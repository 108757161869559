var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Műveletek")]), _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-xs-2"
  }, [_c('comboboxV2', {
    attrs: {
      "id": "type",
      "label": "Típus",
      "name": "type",
      "options": _vm.types
    },
    model: {
      value: _vm.search.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.search, "type_id", $$v);
      },
      expression: "search.type_id"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-1"
  }, [_c('toggle', {
    attrs: {
      "id": "inout",
      "label": "Végrehajtott",
      "name": "inout"
    },
    model: {
      value: _vm.search.inout,
      callback: function ($$v) {
        _vm.$set(_vm.search, "inout", $$v);
      },
      expression: "search.inout"
    }
  })], 1)]), _vm.operations.length > 0 ? _c('div', {
    staticClass: "table-with-title"
  }, [_c('h2', [_vm._v("Műveletek")]), _c('table', {
    staticClass: "list"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.operations, function (operation, i) {
    return _c('tr', {
      key: _vm.opration.name + i
    }, [_c('td', [_vm._v(_vm._s(operation.created))]), _c('td', [_vm._v(_vm._s(operation.name))]), _c('td', [_c('i', {
      staticClass: "customicon customicon-info-circle",
      staticStyle: {
        "font-size": "18px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.showParamsPopup(operation);
        }
      }
    })])]);
  }), 0)])]) : _vm._e(), _vm.operations.length == 0 ? _c('div', {
    staticClass: "normal-text"
  }, [_vm._v(" Nem található művelet. ")]) : _vm._e(), _vm.params_popup.visible ? _c('div', {
    staticClass: "popup-overlayer"
  }, [_c('div', {
    staticClass: "popup",
    staticStyle: {
      "min-width": "500px"
    }
  }, [_c('a', {
    staticClass: "close-btn",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.hideParamsPopup
    }
  }, [_c('i', {
    staticClass: "customicon customicon-close"
  })]), _c('h2', [_vm._v("Paraméterek")]), _c('div', {
    staticClass: "text"
  })])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xs-12"
  }, [_c('h2', [_vm._v("Kereső")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Létrehozva")]), _c('th', [_vm._v("Típus")]), _c('th')])]);

}]

export { render, staticRenderFns }